<template>
  <div class="innerbox">
    <econ-where   v-if="!nodata"  :rangeYear="rangeYear" :curAreaId="curAreaId" :citylist="citylist" @cid-rangeyear="cidRangeyear"
      @change-areaid="changeAreaid"></econ-where>
    <div class="scrollbox">
      <div v-if="nodata">
        <el-result icon="info" title="暂无数据" subTitle="当前城市数据不存在...">
        </el-result>
      </div>
      <div v-else class="report">
        <div class="tablebox">
          <div class="title">{{ tableData.title }}</div>
          <el-table ref="singleTable" @row-click="clickRow" class="table" :data="tableData.table" size="mini"
            :highlight-current-row="true">
            <el-table-column v-for="cell in tableData.header" :key="cell.prop" :prop="cell.prop" :label="cell.label"
              align="center">
            </el-table-column>

          </el-table>
          <div class="footerinfo">
            <time-source :data="{ time: tableData.uptime, source: tableData.source }"></time-source>
          </div>
        </div>
        <div class="piebox">
          <div class="downbox">
            <el-button type="primary" icon="el-icon-download" size="mini" @click.prevent="downloadExcel">导出Excel
            </el-button>
          </div>
          <div class="pie">
            <pie-2 :data="pieData"></pie-2>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>
<script>
import EconWhere from "../../components/cityreserch/EconWhere.vue";
import Pie2 from "../../components/echarts/pie2";
import TimeSource from "../../components/cityreserch/TimeSource.vue";

import { cityYear, gdpData, downloadGdpExcel, gdpRangeYear } from "../../api/econ";
export default {
  name: "VueElementTupaiGdp",
  components: { EconWhere, TimeSource, Pie2 },
  data() {
    return {
      nodata: false,
      rangeYear: [],
      curAreaId: 0,
      citylist: [],
      tableData: {},
      valueObj: null,
      pieData: {
        title: "",
        main: [],
      },
    };
  },

  mounted() {
    this.getWhere();
  },
  computed: {
    shi: {
      get() {
        // alert(this.$store.state.shi)
        // this.curAreaId = this.$store.state.shi;
        return this.$store.state.shi;
      },
      set(shi) {
        this.$store.dispatch("changeShi", shi);
      },
    },
  },
  watch: {
    shi() {
      //   this.currentArea = this.cityList[0].id
      this.getWhere();
    },
  },
  methods: {
    //更改当前的区id
    changeAreaid(id) {
      console.log("wozhixingle==start")
      console.log(this.rangeYear)
      console.log("wozhixingle===end")
      this.getCurrentRange({ city_plate_id: id })
    },

    getCurrentRange(params) {
      params.cateid = 1
      gdpRangeYear(params).then(res => {
        this.nodata = false;
        if (parseInt(res.code) === 1001) {
          this.nodata = true;
        }
        this.rangeYear = res.data.rangeYear;
      })
    },

    //下载excel
    downloadExcel() {
      const params = {
        city_plate_id: this.curAreaId,
        start_year: this.rangeYear[0],
        end_year: this.rangeYear[1],
      };
      params.cateid = 1

      downloadGdpExcel(params).then((res) => {
        if (res.code === 1001) {
          this.$message("此文档不存在...");
        } else {
          this.download = res.data;
          window.location.href = this.download.fileUrl;

          // this.filesToRar([this.download],this.download.renameFileName)
        }
      });
    },
    //子组建返回的事件
    cidRangeyear(obj) {

      this.curAreaId = obj.areaId;


      console.log("obj", obj)
      const params = {
        city_plate_id: obj.areaId,
        start_year: obj.startyear,
        end_year: obj.endyear,
      };
      // params.cateid = 1

      this.getGdpData(params);
    },
    //设置当前选中行
    setCurrent(row) {
      this.$refs.singleTable.setCurrentRow(row);
    },
    //点击行事件
    clickRow(row, column, event) {
      console.log("row", row);
      this.valueObj = row;
      this.handlePieData();
    },

    getWhere() {
      const params = { city_plate_id: this.$store.state.shi, cateid: 1 };

      cityYear(params).then((res) => {

        this.nodata = false;
        if (parseInt(res.code) === 1001) {
          this.nodata = true;
        }
        this.citylist = res.data.citylist;
        this.rangeYear = res.data.rangeYear;
        res.data.citylist.every((item) => {
          if (!item.disabled) {
            this.curAreaId = item.id;
            return false;
          }
        });

        if (this.rangeYear.length !== 0) {
          const params = {
            city_plate_id: this.$store.state.shi,
            start_year: this.rangeYear[0],
            end_year: this.rangeYear[1],
          };
          this.getGdpData(params);
        } else {
          this.tableData = []
          this.nodata = true
          this.$message('暂无数据')
        }

      });
    },

    getGdpData(params) {
      params.cateid = 1
      gdpData(params).then((res) => {

        this.nodata = false;
        if (parseInt(res.code) === 1001) {
          this.nodata = true;
        }
        this.tableData = res.data;
        this.valueObj = null;
        this.handlePieData();

        this.setCurrent(res.data.table[0]);
      });
    },
    //处理pie数据
    handlePieData() {
      let pieData = {};
      let valueObj = this.valueObj || this.tableData.table[0];
      let arr = [
        {
          name: this.tableData.header[2].label,
          value: valueObj.cell3,
        },
        {
          name: this.tableData.header[3].label,
          value: valueObj.cell4,
        },
        {
          name: this.tableData.header[4].label,
          value: valueObj.cell5,
        },
      ];
      pieData.main = arr;
      pieData.title = valueObj.cell1 + "年度地区产业对比";
      this.pieData = pieData;
    },
  },
};
</script>

<style lang="scss" scoped>
.innerbox {
  width: 100%;
  height: auto;
}

.scrollbox {
  height: calc(100vh - 260px);
  overflow: scroll;
  width: 96%;
  margin: 0 auto;
}

.report {
  display: flex;
  justify-content: space-around;
  align-items: center;

  .tablebox {

    width: 40%;

    .title {
      margin-top: 15px;
      font-size: 18px;
      font-weight: 400;
    }
  }

  .piebox {
    width: 40%;
    display: flex;
    flex-direction: column;

    .downbox {
      text-align: right;
    }

    .pie {}
  }
}
</style>